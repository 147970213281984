import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import TemplateBreadCrumbs, {
  breadcrumbsPropTypes,
} from '../../partials/TemplateBreadCrumbs/TemplateBreadCrumbs'
import { Typography, Social } from '../..'
import styles from './AggregatorTemplate.module.css'

const AggregatorTemplate = ({ breadcrumbs, intro, className, children }) => {
  const classes = classNames(['page-wrapper container', styles.root, className])

  return (
    <div className={classes}>
      <div className={styles.layout}>
        <div className={classNames(['col-span-1', styles.social])}>
          <Social orientation="vertical" />
        </div>
        <div className="col-span-11">
          <TemplateBreadCrumbs breadcrumbs={breadcrumbs} className="mb-4" />

          {path(['title'], intro) && (
            <Typography tag="h1" className="mb-8">
              {intro.title}
            </Typography>
          )}
          {path(['text'], intro) && (
            <Typography tag="p" className={styles.leadText}>
              {intro.text}
            </Typography>
          )}

          {children}
        </div>
      </div>
    </div>
  )
}

AggregatorTemplate.propTypes = {
  breadcrumbs: breadcrumbsPropTypes,
  intro: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
}

export default AggregatorTemplate
