import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { itemPropType } from '../propTypes'
import Typography from '../../Typography/Typography'
import Img from '../../../gatsbyWrappers/Img'
import FadeToggle from '../../../animation/FadeToggle/FadeToggle'
import styles from './DropMenu.module.css'
import { TransitionLink } from '../../..'

const DropMenu = ({
  thumbnailSrc,
  excerpt,
  subItems,
  isVisible,
  onMouseLeave = () => {},
}) => {
  const dropMenuClassNames = classNames([styles.dropMenu], {
    [styles.isVisible]: isVisible,
  })

  return (
    <div className={dropMenuClassNames} onMouseLeave={onMouseLeave}>
      <FadeToggle isVisible={isVisible} duration={300}>
        <div className={styles.grid}>
          <Img src={thumbnailSrc} />
          <Typography tag="p">{excerpt}</Typography>
          <ul>
            {subItems.map((item, i) => (
              <li key={i}>
                <TransitionLink to={item.url}>{item.label}</TransitionLink>
              </li>
            ))}
          </ul>
        </div>
      </FadeToggle>
    </div>
  )
}

DropMenu.propTypes = {
  ...itemPropType,
  isVisible: PropTypes.bool,
  onMouseLeave: PropTypes.func,
}
export default DropMenu
