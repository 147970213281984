import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useMap } from 'react-use'
import TextInput from '../../elements/TextInput/TextInput'
import TextArea from '../../elements/TextArea/TextArea'
import Button from '../../elements/Button/Button'

const ContactForm = ({ className = '', onSubmit = () => {} }) => {
  const [values, { set, reset }] = useMap({
    name: '',
    email: '',
    company: '',
    message: '',
  })

  const textInputClassNames = classNames([className, 'text-input'])

  const handleChange = ({ target: { name, value } }) => {
    set(name, value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    onSubmit(values)
    reset()
  }
  return (
    <form className={textInputClassNames} onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <TextInput
            type="text"
            label="name"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
          <TextInput
            type="email"
            label="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          <TextInput
            type="text"
            label="company / organization"
            onChange={handleChange}
            value={values.company}
            name="company"
          />
        </div>
        <div className="flex flex-col">
          <TextArea
            label="message"
            name="message"
            value={values.message}
            onChange={handleChange}
          />
          <Button
            kind="primary"
            type="submit"
            className="self-end mt-6"
            onSubmit={e => handleSubmit(e)}
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  )
}

ContactForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default ContactForm
