import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Head = ({ title, description }) => {
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content="/" />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/do95jfmcf/image/upload/v1595941906/website/misc/og-image_wducev.png"
      />
      <link
        async="async"
        href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

Head.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Head
