import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '../../Typography/Typography'
import styles from './CategorizedItem.module.css'

const CategorizedItem = ({ className = '', children, category }) => {
  const categorizedItemClassNames = classNames([styles.item, className])

  return (
    <article className={categorizedItemClassNames}>
      <section>{children}</section>
      {category && (
        <Typography tag="span" type="breadcrumb">
          {category}
        </Typography>
      )}
    </article>
  )
}

CategorizedItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
}

export default CategorizedItem
