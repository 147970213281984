import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '../Typography/Typography'
import styles from './SectionHeader.module.css'

const SectionHeader = ({
  className = '',
  title = '',
  subTitle = '',
  Tag = 'h3',
}) => {
  const sectionHeaderClassNames = classNames([styles.sectionHeader, className])

  return (
    <Tag className={sectionHeaderClassNames}>
      <Typography className={styles.title} tag="span" type="subHead1">
        {title}
      </Typography>
      {subTitle && (
        <Typography className={styles.subtitle} tag="span" type="subHead2">
          {subTitle}
        </Typography>
      )}
    </Tag>
  )
}

SectionHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  Tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

export default SectionHeader
