import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AspectRatio from 'react-aspect-ratio'
import styles from './AspectRatio.module.css'

const _AspectRatio = ({ ratio, className, children, style = {} }) => {
  const classes = classNames([styles.root, className])

  return (
    <AspectRatio className={classes} ratio={ratio} style={style}>
      {children}
    </AspectRatio>
  )
}

_AspectRatio.propTypes = {
  ratio: PropTypes.string,
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}

export default _AspectRatio
