import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '../Typography/Typography'
import styles from './TextArea.module.css'

const TextArea = ({
  className = '',
  label,
  name,
  id,
  value,
  onChange = () => {},
}) => {
  const inputTextAreaClassNames = classNames([styles.textArea, className])
  const _id = id || name

  return (
    <div className={inputTextAreaClassNames}>
      <Typography tag="label" type="fieldLabel" htmlFor={_id}>
        {label}
      </Typography>
      <textarea
        id={_id || name}
        name={name}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

TextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default TextArea
