import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AspectRatio from '../AspectRatio/AspectRatio'
import styles from './AggregatorPin.module.css'

const AggregatorPin = ({
  className = '',
  children,
  text,
  imageUrl,
  onClick = () => {},
}) => {
  const aggregatorClassNames = classNames([styles.aggregatorPin, className])

  const aggregatorTextClassNames = classNames([
    styles.aggregatorPinText,
    className,
  ])

  const rootStyles = {
    backgroundImage: `url(${imageUrl})`,
    maxWidth: '500px',
    maxHeight: '500px',
  }

  return (
    <AspectRatio ratio="1/1">
      <div
        className={aggregatorClassNames}
        onClick={onClick}
        style={rootStyles}
      >
        <div className={aggregatorTextClassNames}>{text}</div>
        {children}
      </div>
    </AspectRatio>
  )
}

AggregatorPin.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  imageUrl: PropTypes.string,
  kind: PropTypes.oneOf(['base', 'photo', 'event', 'home', 'upcoming']),
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default AggregatorPin
