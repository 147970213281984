module.exports = {
  purge: false,
  prefix: '',
  important: false,
  separator: ':',
  theme: {
    extend: {
      screens: {
        mobile: { max: '639px' },
        // => @media (max-width: 639px) { ... }
      },
    },
    colors: {
      black: '#000',
      white: '#fff',
      gray: {
        400: '#F4F4F4',
        500: '#ECECEC',
        600: '#E0E0E0',
        700: '#A0A0A0',
        800: '#4D4D4D',
        900: '#3C3C3C',
      },
      berthaYellow: '#FCEA10',
      berthaGreen: '#8DC63F',
      limeGreen: '#9FC44E',
      green: '#C9DD2E',
      teal: {
        light: '#64C2B3',
        dark: '#008A82',
      },
      blue: '#00BCD6',
    },
    spacing: {
      px: '1px',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      28: '7rem',
      32: '8rem',
      34: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      56: '14rem',
      64: '16rem',
      68: '18rem',
      72: '20rem',
      74: '21.56rem',
      76: '22rem',
      80: '24rem',
      84: '26rem',
      88: '28rem',
      92: '30rem',
      96: '32rem',
    },
    backgroundColor: theme => theme('colors'),
    fontFamily: {
      sans: [
        'Ubuntu',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '4.5rem',
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
  },
  corePlugins: {},
  plugins: [],
}
