import React from 'react'
import PropTypes from 'prop-types'

import AniLink from 'gatsby-plugin-transition-link/AniLink'

const TransitionLink = ({ children, ...props }) => {
  return (
    <AniLink paintDrip duration={0.5} hex="#3C3C3C" {...props}>
      {children}
    </AniLink>
  )
}

TransitionLink.propTypes = {
  children: PropTypes.node,
}

export default TransitionLink
