import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../Typography/Typography'
import styles from './SearchHeader.module.css'
import { Index } from 'elasticlunr'
import SearchResultsList from './SearchResultsList'

const SearchHeader = ({
  categories,
  onSearchSubmit = () => {},
  searchIndex,
}) => {
  const [searchQuery, setSearchQuery] = React.useState('')
  const [searchResults, setSearchResults] = React.useState([])
  const search = evt => {
    const query = evt.target.value
    const index = Index.load(searchIndex) || []

    setSearchQuery(query)
    setSearchResults(
      index.search(query, { expand: true }).map(({ ref }) => {
        return index.documentStore.getDoc(ref)
      }),
    )
  }

  return (
    <div className={styles.searchHeader}>
      <div className={styles.wrapper}>
        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="what are you searching for?"
            value={searchQuery}
            onChange={search}
          />
          <button type="submit" onSubmit={() => onSearchSubmit(searchQuery)}>
            <div className={styles.btnSearchCircle} />
            <div className={styles.btnSearchRectangle} />
            <span className="sr-only">Search</span>
          </button>
        </div>
        <SearchResultsList searchResults={searchResults} />
        {categories.length > 0 && (
          <Typography
            tag="h4"
            type="sideNabSubHead"
            className={styles.catTitle}
          >
            Other ways to search
          </Typography>
        )}
        {categories.map((category, i) => (
          <div className={styles.categoryItem} key={i}>
            <Typography tag="span" type="sideNavBody">
              <a href={category.url}>{category.label}</a>
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

SearchHeader.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  onSearchSubmit: PropTypes.func,
  searchIndex: PropTypes.objectOf(PropTypes.string),
}

export default SearchHeader
