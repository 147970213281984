import { createElement } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './Typography.module.css'

const Typography = ({ children, className, type, tag = 'p', ...rest }) => {
  const typographyClassNames = classNames([styles[type], className])
  return createElement(
    tag,
    {
      className: typographyClassNames,
      ...rest,
    },
    children,
  )
}

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'aggregatorIntro',
    'miniAggregatorIntro',
    'subHead1',
    'subHead2',
    'lead',
    'body',
    'accordionHeader',
    'pullQuote1',
    'pullQuote2',
    'aggregatorPinHead',
    'aggregatorPinSubHead',
    'aggregatorPinBody',
    'breadcrumb',
    'boxHeading',
    'sideNavHead',
    'sideNabSubHead',
    'sideNavBody',
    'sideNavBodyLight',
    'contentPinHead',
    'contentPinBody',
    'fieldLabel',
  ]),
  tag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'strong',
    'em',
    'span',
    'li',
  ]),
}

export default Typography
