import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '../Typography/Typography'
import styles from './TextInput.module.css'

const TextInput = ({
  className = '',
  type,
  label,
  name,
  id,
  value,
  onChange = () => {},
}) => {
  const inputFieldClassNames = classNames([styles.textInput, className])
  const _id = id || name

  return (
    <div className={inputFieldClassNames}>
      <Typography tag="label" type="fieldLabel" htmlFor={_id}>
        {label}
      </Typography>
      <input
        id={_id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'number', 'password']),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default TextInput
