import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './YouTube.module.css'

const YouTube = ({ className, url, title }) => {
  const classes = classNames([styles.root, className])

  return (
    <div className={classes}>
      <iframe
        src={url}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  )
}

YouTube.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default YouTube
