import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Tabs.module.css'

export const TabContext = createContext()

const Tabs = ({ initialValue, className = '', children }) => {
  const tabsClassNames = classNames([styles.tabs, className])
  const [activeTab, changeTab] = useState(initialValue)
  const tabProviderValue = { activeTab, changeTab }

  return (
    <TabContext.Provider value={tabProviderValue}>
      <div className={tabsClassNames}>{children}</div>
    </TabContext.Provider>
  )
}

Tabs.propTypes = {
  className: PropTypes.string,
  initialValue: PropTypes.string,
  children: PropTypes.node,
}

export default Tabs
