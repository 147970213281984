import React from 'react'
import facebookIcon from '../../../icons/social/facebook.svg'
import linkedinIcon from '../../../icons/social/linkedin.svg'
import youtubeIcon from '../../../icons/social/youtube.svg'
import twitterIcon from '../../../icons/social/twitter.svg'
import emailIcon from '../../../icons/social/email.svg'
import instagramIcon from '../../../icons/social/instagram.svg'
import footerLogos from '../../../img/footer-logos.svg'
import styles from './Footer.module.css'
import { TransitionLink } from '../..'

const Footer = () => {
  return (
    <footer className={styles.primaryFooter}>
      <div className={styles.container}>
        <section>
          <div>
            <h4>Newsletters</h4>
            <a
              href="https://uct.everlytic.net/public/forms/h/E4v0Cg7HjY2q506O/ZjEzZTExMTNmMGNhMDNhNjhhMjYxOGEzZjVkZWRiOTBjZjQ0MDRjMw=="
              target="__blank"
              rel="noopener noreferrer"
              className="button is-inverse"
            >
              Subscribe
            </a>
          </div>
          <div>
            <h4>Read more about us</h4>
            <TransitionLink
              to="/about-us/press-office/"
              className="button is-inverse"
            >
              Press Office
            </TransitionLink>
            <TransitionLink
              to="/about-us/our-resources"
              className="button is-inverse"
            >
              Resources
            </TransitionLink>
          </div>
        </section>
        <section>
          <div>
            <h4>Connect with us</h4>
            <ul className={styles.social}>
              <li>
                <a
                  href="https://www.facebook.com/BerthaCentre"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebookIcon} alt="Facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/bertha-centre/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedinIcon} alt="Linked In" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCeoaeKI96RGx77CF-loM32Q/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtubeIcon} alt="YouTube" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/BerthaCentre"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterIcon} alt="Twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/theberthacentre/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagramIcon} alt="Instagram" />
                </a>
              </li>
              <li>
                <a href="mailto:berthacentre.gsb@uct.ac.za">
                  <img src={emailIcon} alt="Email" />
                </a>
              </li>
            </ul>
          </div>
          <div>
            <img src={footerLogos} />
          </div>
        </section>
      </div>
      <div className={styles.copyright}>
        <p>349 &copy; UCT GSB - All rights reserved Access to Information.</p>
      </div>
    </footer>
  )
}

export default Footer
