import React from 'react'
import PropTypes from 'prop-types'
import { useSize } from 'react-use'
import Img from '../../gatsbyWrappers/Img'
import Typography from '../Typography/Typography'
import styles from './PrimaryHeader.module.css'
import { cloudinaryFluidImage } from '../../propTypes/cloudinaryProps'

const PrimaryHeader = ({ image, title, subTitle }) => {
  const [img] = useSize(() => (
    <div className={styles.image}>
      <Img src={image} alt={title} />
    </div>
  ))

  return (
    <div className={styles.primaryHeader}>
      {img}
      <div className={styles.text}>
        <Typography tag="h1" type="subHead1" className={styles.title}>
          {title}
        </Typography>
        <Typography tag="h2" type="subHead2" className={styles.subtitle}>
          {subTitle}
        </Typography>
      </div>
    </div>
  )
}

PrimaryHeader.propTypes = {
  image: cloudinaryFluidImage,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

export default PrimaryHeader
