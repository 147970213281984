import PropTypes from 'prop-types'

export const cloudinaryFixedImage = PropTypes.shape({
  childCloudinaryMediaImage: PropTypes.shape({
    fixed: PropTypes.object,
  }),
})

export const cloudinaryFluidImage = PropTypes.shape({
  childCloudinaryMediaImage: PropTypes.shape({
    fluid: PropTypes.object,
  }),
})
