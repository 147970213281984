import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { titleCase } from '../../../utils'
import SectionHeader from '../../elements/SectionHeader/SectionHeader'
import styles from './PinBlockGroup.module.css'

const PinBlockGroup = ({
  sectionTitle,
  sectionSubTitle,
  columns = 4,
  children,
  spacing = 'sm',
  className = '',
}) => {
  const classes = classNames([
    styles.root,
    styles[`columns${columns}`],
    styles[`spacing${titleCase(spacing)}`],
    className,
  ])

  return (
    <div className={classes}>
      <div className={styles.headerBlock}>
        {sectionTitle && (
          <SectionHeader title={sectionTitle} subTitle={sectionSubTitle} />
        )}
      </div>
      <div className={styles.blocks}>{children}</div>
    </div>
  )
}

PinBlockGroup.propTypes = {
  sectionTitle: PropTypes.string,
  sectionSubTitle: PropTypes.string,
  columns: PropTypes.oneOf([2, 4]),
  children: PropTypes.arrayOf(PropTypes.node),
  spacing: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
}

export default PinBlockGroup
