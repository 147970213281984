import React from 'react'
import PropTypes from 'prop-types'
import { SideNav, Typography } from '../..'
import { useStaticQuery, graphql } from 'gatsby'
import { pathOr } from 'ramda'
import { flattenNodes } from '../../../utils'
import TransitionLink from '../../elements/TransitionLink/TransitionLink'

const PressOfficeWidget = ({ className }) => {
  const data = useStaticQuery(graphql`
    query PressOfficeWidget {
      pressReleases: allMdx(
        filter: { fields: { contentType: { eq: "pressReleases" } } }
        sort: { fields: frontmatter___publishedDate, order: DESC }
        limit: 10
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              excerpt
              publishedDate
              externalUrl
            }
          }
        }
      }
    }
  `)

  const releases = pathOr([], ['pressReleases', 'edges'], data)

  return (
    <SideNav heading="Press Office" className={className}>
      {flattenNodes(releases).map((r, i) => (
        <SideNav.CategorizedItem key={i}>
          <Typography tag="h4" type="sideNavBody">
            {r.externalUrl ? (
              <a href={r.externalUrl} target="_blank" rel="noreferrer">
                {r.title}
              </a>
            ) : (
              <TransitionLink to={r.slug}>{r.title}</TransitionLink>
            )}
          </Typography>
        </SideNav.CategorizedItem>
      ))}
    </SideNav>
  )
}

PressOfficeWidget.propTypes = {
  className: PropTypes.string,
}

export default PressOfficeWidget
