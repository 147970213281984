import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Img from '../../gatsbyWrappers/Img'
import Typography from '../Typography/Typography'
import styles from './PressReleaseExcerpt.module.css'

const PressReleaseExcerpt = ({
  className,
  title,
  excerpt,
  thumbnailSrc,
  date,
}) => {
  const pressReleaseClassNames = classNames([
    styles.pressReleaseExcerpt,
    className,
  ])

  return (
    <div className={pressReleaseClassNames}>
      <Img className={styles.thumbnail} src={thumbnailSrc} alt={title} />
      <section>
        <Typography tag="h4" type="contentPinHead" className={styles.title}>
          {title}
        </Typography>
        <Typography tag="p" className={styles.excerpt}>
          {date && <span className={styles.date}>{date}.&nbsp;</span>}
          {excerpt}
        </Typography>
      </section>
    </div>
  )
}

PressReleaseExcerpt.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  thumbnailSrc: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  date: PropTypes.string,
}

export default PressReleaseExcerpt
