import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './CourseItem.module.css'

const CourseItem = ({
  className = '',
  children,
  deadline = '',
  startDate = '',
  imageUrl = '',
  imageAlt = '',
}) => {
  const courseItemClassNames = classNames([styles.item, className])

  return (
    <article className={courseItemClassNames}>
      <div>
        <h5>{children}</h5>
        <section className="course-sidenav-item--meta">
          <strong>Application deadline</strong>
          <span>{deadline}</span>
          <strong>Course starts</strong>
          <span>{startDate}</span>
        </section>
      </div>
      {imageUrl && <img src={imageUrl} alt={imageAlt} />}
    </article>
  )
}

CourseItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  deadline: PropTypes.string,
  startDate: PropTypes.string,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default CourseItem
