import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import GoogleMapReact from 'google-map-react'
import mapStyles from './MapStyle'

const Map = ({ className = '', lat, lng, zoom }) => {
  const mapClassNames = classNames([className], {})
  const mapOptions = {
    styles: mapStyles,
  }
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat, lng },
      map,
      title: 'Bertha Centre',
    })
    return marker
  }
  return (
    <div className={mapClassNames} style={{ height: '70vh', width: '100%' }}>
      <GoogleMapReact
        options={mapOptions}
        bootstrapURLKeys={{
          key: 'AIzaSyAJm3S6KrORl1icgUTuVaVsQoFDEWRG-aQ',
          language: 'en',
        }}
        defaultCenter={{
          lat,
          lng,
        }}
        defaultZoom={zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      />
    </div>
  )
}

Map.propTypes = {
  className: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
}

export default Map
