import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import facebookIcon from '../../../icons/social/facebook-dark.svg'
import linkedinIcon from '../../../icons/social/linkedin-dark.svg'
import youtubeIcon from '../../../icons/social/youtube-dark.svg'
import twitterIcon from '../../../icons/social/twitter-dark.svg'
import styles from './Social.module.css'

const Social = ({
  className = '',
  orientation,
  facebookUrl = 'https://www.facebook.com/BerthaCentre',
  linkedinUrl = 'https://www.linkedin.com/company/bertha-centre/',
  youtubeUrl = 'https://www.youtube.com/channel/UCeoaeKI96RGx77CF-loM32Q/featured',
  twitterUrl = 'https://twitter.com/BerthaCentre',
}) => {
  const socialClassNames = classNames([styles.social, className], {
    [styles.vertical]: orientation === 'vertical',
    [styles.horizontal]: orientation === 'horizontal',
  })

  return (
    <div className={socialClassNames}>
      <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
        <img src={facebookIcon} alt="Facebook" />
      </a>
      <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
        <img src={linkedinIcon} alt="LinkedIn" />
      </a>
      <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
        <img src={youtubeIcon} alt="YouTube" />
      </a>
      <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
        <img src={twitterIcon} alt="Twitter" />
      </a>
    </div>
  )
}

Social.propTypes = {
  className: PropTypes.string,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  facebookUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
}

export default Social
