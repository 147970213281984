import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Img from '../../gatsbyWrappers/Img'
import { cloudinaryFixedImage } from '../../propTypes/cloudinaryProps'
import Typography from '../Typography/Typography'
import styles from './AggregatorPin4.module.css'
import { TransitionLink } from '../..'

const AggregatorPin4 = ({
  className = '',
  title,
  author,
  publisher,
  thumbnailSrc,
  href,
  children,
}) => {
  const aggregatorPin3ClassNames = classNames([
    styles.aggregatorPin4,
    className,
  ])

  return (
    <div className={aggregatorPin3ClassNames}>
      <TransitionLink to={href}>
        {thumbnailSrc && (
          <Img className={styles.thumbnail} src={thumbnailSrc} alt={title} />
        )}
        <div>
          <Typography tag="h3" type="contentPinHead" className={styles.title}>
            {title}
          </Typography>
          {author && <p className="text-lg mb-2">Author: {author}</p>}
          {publisher && <p className="text-lg">Published by {publisher}</p>}
          {children}
        </div>
      </TransitionLink>
    </div>
  )
}

AggregatorPin4.propTypes = {
  className: PropTypes.string,
  kind: PropTypes.oneOf(['base']),
  title: PropTypes.string,
  author: PropTypes.string,
  publisher: PropTypes.string,
  thumbnailSrc: cloudinaryFixedImage,
  href: PropTypes.string,
  children: PropTypes.string,
}

export default AggregatorPin4
