import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTransition, animated } from 'react-spring'

const visibleStyle = { opacity: 1, overflow: 'visible' }
const hiddenStyle = { opacity: 0, overflow: 'hidden' }

const FadeToggle = ({
  className,
  isVisible,
  forceSlideIn,
  duration = 200,
  children,
}) => {
  const isVisibleOnMount = useRef(isVisible && !forceSlideIn)
  const containerRef = useRef(null)
  const innerRef = useRef(null)

  const transitions = useTransition(isVisible, null, {
    duration: 100,
    enter: () => async (next, cancel) => {
      cancel()
      await next({ opacity: 1, overflow: 'hidden' })
      await next(visibleStyle)
    },
    leave: () => async (next, cancel) => {
      cancel()
      await next({ overflow: 'hidden' })
      await next(hiddenStyle)
      isVisibleOnMount.current = false
    },
    from: isVisibleOnMount.current ? visibleStyle : hiddenStyle,
    unique: true,
    config: {
      duration,
    },
  })

  return transitions.map(({ item: show, props: springProps, key }) => {
    if (show) {
      return (
        <animated.div
          className={className}
          ref={containerRef}
          key={key}
          style={springProps}
        >
          <div ref={innerRef}>{children}</div>
        </animated.div>
      )
    }
  })
}

FadeToggle.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  forceSlideIn: PropTypes.bool,
  duration: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default FadeToggle
