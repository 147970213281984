import React from 'react'
import classNames from 'classnames'
import {
  Accordion as AccessibleAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { itemsPropType } from '../propTypes'
import Typography from '../../Typography/Typography'
import styles from './MobileNav.module.css'
import { TransitionLink } from '../../..'

const MobileNav = ({ items = [] }) => {
  const mobileNavClassNames = classNames([styles.root])

  return (
    <div className={mobileNavClassNames}>
      <AccessibleAccordion>
        {items.map((item, i) => (
          <AccordionItem key={i}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <Typography tag="span" type="accordionHeader">
                  {item.label}
                </Typography>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <ul>
                {item.subItems.map((si, i) => (
                  <li key={i}>
                    <TransitionLink to={si.url}>{si.label}</TransitionLink>
                  </li>
                ))}
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </AccessibleAccordion>
    </div>
  )
}

MobileNav.propTypes = {
  items: itemsPropType,
}

export default MobileNav
