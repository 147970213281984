import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import { Typography, Social, TemplateBreadCrumbs } from '../..'
import styles from './CategoryTemplate.module.css'

const CategoryTemplate = ({ breadcrumbs, intro, className, children }) => {
  const classes = classNames(['page-wrapper container', styles.root, className])

  return (
    <div className={classes}>
      <div className={styles.layout}>
        <div className={classNames(['col-span-1', styles.social])}>
          <Social orientation="vertical" />
        </div>
        <div className="col-span-11">
          <TemplateBreadCrumbs breadcrumbs={breadcrumbs} />

          {path(['title'], intro) && (
            <Typography tag="h1" className="mb-8">
              {intro.title}
            </Typography>
          )}
          {path(['text'], intro) && (
            <Typography tag="p" className={styles.leadText}>
              {intro.text}
            </Typography>
          )}

          {children}
        </div>
      </div>
    </div>
  )
}

CategoryTemplate.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  intro: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
}

export default CategoryTemplate
