import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../Typography/Typography'
import styles from './SearchResultsList.module.css'
import { TransitionLink } from '../..'

const SearchResultsList = ({ searchResults }) => {
  if (searchResults.length > 0) {
    return (
      <>
        <ul className={styles.resultList}>
          {searchResults.map(page => {
            return (
              <li key={page.id} className={styles.resultItem}>
                <TransitionLink to={page.slug}>{page.title}</TransitionLink>
              </li>
            )
          })}
        </ul>
        <Typography type="fieldLabel" style={{ marginBottom: '5px' }}>
          Results ({searchResults.length})
        </Typography>
      </>
    )
  } else {
    return <></>
  }
}

SearchResultsList.propTypes = {
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
}

export default SearchResultsList
