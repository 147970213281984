import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CourseItem from './CourseItem/CourseItem'
import CategorizedItem from './CategorizedItem/CategorizedItem'
import ThumbnailItem from './ThumbnailItem/ThumbnailItem'
import styles from './SideNav.module.css'

const SideNav = ({ className = '', heading = 'Press Office', children }) => {
  const sideNavClassNames = classNames([styles.sidenav, className])

  return (
    <nav className={sideNavClassNames}>
      <h3>{heading}</h3>
      {children}
    </nav>
  )
}

const childrenComponentPropTypes = PropTypes.oneOfType([
  PropTypes.instanceOf(CourseItem),
  PropTypes.instanceOf(CategorizedItem),
  PropTypes.instanceOf(ThumbnailItem),
])

SideNav.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(childrenComponentPropTypes),
    childrenComponentPropTypes,
  ]).isRequired,
}

SideNav.CourseItem = CourseItem
SideNav.CategorizedItem = CategorizedItem
SideNav.ThumbnailItem = ThumbnailItem

export default SideNav
