import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Button.module.css'

const Button = ({
  className = '',
  kind = 'base',
  type = 'button',
  size = 'default',
  onClick = () => {},
  tag = 'button',
  children,
  ...rest
}) => {
  const buttonClassNames = classNames([styles.button, className], {
    [styles.isPrimary]: kind === 'primary',
    [styles.isInverse]: kind === 'inverse',
    [styles.isSmall]: size === 'sm',
    [styles.isMedium]: size === 'md',
    [styles.isLarge]: size === 'lg',
    [styles.link]: tag === 'a',
  })

  const TagName = tag

  return (
    <TagName
      type={tag === 'button' ? type : undefined}
      className={buttonClassNames}
      onClick={onClick}
      {...rest}
    >
      {children}
    </TagName>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  kind: PropTypes.oneOf(['base', 'primary', 'inverse']),
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['default', 'sm', 'md', 'lg']),
  onClick: PropTypes.func,
  tag: PropTypes.oneOf[('a', 'button')],
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default Button
