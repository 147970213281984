import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ReadMore.module.css'

const ReadMore = ({ className, children }) => {
  return (
    <span className={classNames([styles.root, className])}>{children}</span>
  )
}

ReadMore.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

export default ReadMore
