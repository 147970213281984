import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '../../Typography/Typography'
import styles from './BreadcrumbItem.module.css'

const BreadcrumbItem = ({ className = '', children }) => {
  const breadcrumbItemClassNames = classNames([
    styles.breadcrumbItem,
    className,
  ])

  return (
    <Typography tag="li" type="breadcrumb" className={breadcrumbItemClassNames}>
      {children}
    </Typography>
  )
}

BreadcrumbItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default BreadcrumbItem
