import PropTypes from 'prop-types'
import { cloudinaryFixedImage } from '../../propTypes/cloudinaryProps'

export const itemPropType = {
  label: PropTypes.string,
  thumbnailSrc: cloudinaryFixedImage,
  excerpt: PropTypes.string,
  isActive: PropTypes.bool,
  subItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
}

export const itemsPropType = PropTypes.arrayOf(PropTypes.shape(itemPropType))
