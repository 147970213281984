import React, { useState, useRef, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useMedia, useToggle } from 'react-use'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../../tailwind.config'
import SlideToggle from '../../animation/SlideToggle/SlideToggle'
import DesktopNav from './DesktopNav/DesktopNav'
import MobileNav from './MobileNav/MobileNav'
import logo from './logo.svg'
import searchIcon from '../../../icons/search.svg'
import menuIcon from '../../../icons/menu.svg'
import closeIcon from '../../../icons/close-light.svg'
import styles from './MainNav.module.css'
import { itemsPropType } from './propTypes'
import SearchHeader from '../SearchHeader/SearchHeader'
import { TransitionLink } from '../..'

const categories = [
  // { label: 'Researching', url: '' },
  // { label: 'Catalysing', url: '' },
  // { label: 'Convening', url: '' },
  // { label: 'Educating', url: '' },
  // { label: 'Impact Investing', url: '' },
  // { label: 'Youth', url: '' },
  // { label: 'Health', url: '' },
  // { label: 'Innovative Finance', url: '' },
  // { label: 'Team Member', url: '' },
]

const fullConfig = resolveConfig(tailwindConfig)

const MainNav = ({ className, items }) => {
  const node = useRef()
  const isLarge = useMedia(`(min-width: ${fullConfig.theme.screens.lg})`)
  const [isOpen, setIsOpen] = useToggle(false)
  const [isSearchOpen, setSearchOpen] = useState(false)
  const handleClickOutside = e => {
    if (!node.current.contains(e.target)) {
      setSearchOpen(false)
    }
  }
  useEffect(() => {
    if (isSearchOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSearchOpen])

  const mainNavClassNames = classNames([styles.mainNav, className], {
    [styles.isMobile]: !isLarge,
  })

  const SearchButton = () => (
    <button
      className={styles.search}
      onClick={() => setSearchOpen(!isSearchOpen)}
    >
      <img src={searchIcon} alt="search" width="30px" height="30px" />
    </button>
  )

  const toggleNav = () => (isOpen ? setIsOpen(false) : setIsOpen(true))

  return (
    <StaticQuery
      query={graphql`
        query SearchIndexQuery {
          siteSearchIndex {
            index
          }
        }
      `}
      render={data => (
        <>
          <nav className={mainNavClassNames}>
            <div className={styles.navBar}>
              <TransitionLink to="/" className={styles.brand}>
                <img src={logo} alt="Bertha Centre Logo" />
              </TransitionLink>
              <div className={styles.menu}>
                {!isLarge && (
                  <button className={styles.toggleNav} onClick={toggleNav}>
                    <img
                      src={isOpen ? closeIcon : menuIcon}
                      alt="menu"
                      width="40px"
                    />
                  </button>
                )}
                {isLarge && <DesktopNav items={items} />}
                {isLarge && <SearchButton />}
                {!isLarge && <SearchButton />}
              </div>
            </div>
            <SlideToggle isVisible={isOpen}>
              <MobileNav isOpen={isOpen} items={items} />
            </SlideToggle>
          </nav>
          {isSearchOpen && (
            <div ref={node}>
              <SearchHeader
                categories={categories}
                searchIndex={data.siteSearchIndex.index}
              />
            </div>
          )}
        </>
      )}
    />
  )
}

MainNav.propTypes = {
  className: PropTypes.string,
  items: itemsPropType,
}

export default MainNav
