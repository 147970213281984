import React, { useState } from 'react'
import classNames from 'classnames'
import { itemsPropType } from '../propTypes'
import DropMenu from './DropMenu'
import styles from './DesktopNav.module.css'
import { TransitionLink } from '../../..'

const DesktopNav = ({ items = [] }) => {
  const [visibleIndex, setVisibleIndex] = useState(-1)

  const handleMouseLeave = () => {
    setVisibleIndex(-1)
  }

  return (
    <ul className={styles.desktopNav} onMouseLeave={handleMouseLeave}>
      {items.map((item, i) => {
        const buttonClassNames = classNames({
          [styles.isActive]: item.isActive || visibleIndex === i,
        })

        return (
          <li key={i}>
            <TransitionLink
              to={item.subItems[0].url}
              className={buttonClassNames}
              onMouseEnter={() => setVisibleIndex(i)}
              activeClassName={styles.isActive}
              partiallyActive={true}
            >
              {item.label}
            </TransitionLink>
            <DropMenu
              {...item}
              isVisible={visibleIndex === i}
              onMouseLeave={handleMouseLeave}
            />
          </li>
        )
      })}
    </ul>
  )
}

DesktopNav.propTypes = {
  items: itemsPropType,
}

export default DesktopNav
