import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { path, pathOr } from 'ramda'
import {
  cloudinaryFixedImage,
  cloudinaryFluidImage,
} from '../propTypes/cloudinaryProps'

const GatsbyImgFallback = ({ src, alt = '', className }) => {
  const childCloudinaryMediaImage = path(['childCloudinaryMediaImage'], src)

  if (
    typeof window !== 'undefined' &&
    typeof window.NetlifyCmsApp !== 'undefined'
  ) {
    return <img className={className} src={src} alt={alt} />
  }

  if (path(['fluid'], childCloudinaryMediaImage)) {
    return (
      <Img
        className={className}
        fluid={childCloudinaryMediaImage.fluid}
        alt={alt}
      />
    )
  }

  if (path(['fixed'], childCloudinaryMediaImage)) {
    return (
      <Img
        className={className}
        fixed={childCloudinaryMediaImage.fixed}
        alt={alt}
        sizes={{
          ...childCloudinaryMediaImage.fixed,
          aspectRatio: pathOr(
            1,
            ['fixed', 'aspectRatio'],
            childCloudinaryMediaImage,
          ),
        }}
      />
    )
  }

  if (typeof src === 'string') {
    return <img className={className} src={src} alt={alt} />
  }

  return 'Invalid image'
}

GatsbyImgFallback.propTypes = {
  src: PropTypes.oneOfType([cloudinaryFixedImage, cloudinaryFluidImage]),
  alt: PropTypes.string,
}

export default GatsbyImgFallback
