import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  eachMonthOfInterval,
  getYear,
  format,
  addYears,
  getTime,
} from 'date-fns'
import Typography from '../Typography/Typography'
import styles from './Calendar.module.css'
import {
  range,
  reverse,
  pipe,
  map,
  take,
  sort,
  head,
  filter,
  isEmpty,
} from 'ramda'

const getMonths = year =>
  eachMonthOfInterval({
    start: new Date(year - 1, 12, 1),
    end: new Date(year, 11, 1),
  })

const sortDates = pipe(
  sort((a, b) => {
    return getTime(a) - getTime(b)
  }),
)

const getFirstYear = pipe(head, date => format(date, 'yyyy'))

const Calendar = ({ dates = [], onMonthClick = () => {} }) => {
  const sortedDates = sortDates(dates)
  const startYear = getFirstYear(sortedDates)

  const monthHasDates = date => {
    return !pipe(
      map(date => format(date, 'yyyy-MM')),
      filter(d => d === date),
      isEmpty,
    )(dates)
  }

  const years = reverse(
    range(
      parseInt(startYear),
      parseInt(format(addYears(Date.now(), 1), 'yyyy')),
    ),
  )
  const [year, setYear] = useState(getYear(Date.now()))
  const [months, setMonths] = useState(getMonths(year))
  const [activeMonth, setActiveMonth] = useState(null)

  useEffect(() => {
    setMonths(getMonths(year))
    setActiveMonth(null)
  }, [year])

  const handleMonthClick = useCallback(
    date => {
      onMonthClick(date)
      setActiveMonth(format(date, 'MM'))
    },
    [year],
  )

  return (
    <div className={styles.calendar}>
      <Typography tag="strong" type="h3">
        {year}
      </Typography>
      <div className={styles.months}>
        {months.map(month => {
          const monthNumber = format(month, 'MM')
          return (
            <button
              className={classNames({
                [styles.active]: activeMonth === monthNumber,
              })}
              key={month}
              onClick={handleMonthClick.bind(null, month)}
              disabled={!monthHasDates(`${year}-${monthNumber}`)}
            >
              {format(month, 'MMM')}
            </button>
          )
        })}
      </div>
      <ul className={styles.pagination}>
        {/* <Pagination initialPage={2020} pageCount={2020} onChange={setYear} /> */}
        {pipe(
          map(y => (
            <li key={y} className={classNames({ [styles.active]: y === year })}>
              <button onClick={() => setYear(y)}>{y}</button>
            </li>
          )),
          take(6),
        )(years)}
        {/* <li>
          <button>Next</button>
        </li>
        <li>
          <button>Last</button>
        </li> */}
      </ul>
    </div>
  )
}

Calendar.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onMonthClick: PropTypes.func,
}

export default Calendar
