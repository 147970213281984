import React from 'react'
import { pipe, map, filter, sortBy, path, prop, pathOr } from 'ramda'
import { graphql, useStaticQuery } from 'gatsby'
import useNavbarPages from '../../graphqlFragments/useNavbarPages'
import MainNav from '../../elements/MainNav/MainNav'

const flattenCategories = pipe(
  prop('edges'),
  map(path(['node'])),
  map(n => ({
    ...n.fields,
    ...n.frontmatter,
  })),
)

const Navbar = () => {
  const pages = useNavbarPages()
  const categories = useStaticQuery(
    graphql`
      query NavBarQuery {
        courses: allMdx(
          filter: { fields: { contentType: { eq: "courseCategories" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
        # catalysing: allMdx(
        #   filter: { fields: { contentType: { eq: "projectCategories" } } }
        # ) {
        #   edges {
        #     node {
        #       fields {
        #         slug
        #       }
        #       frontmatter {
        #         title
        #       }
        #     }
        #   }
        # }
        research: allMdx(
          filter: { fields: { contentType: { eq: "publicationCategories" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
        portfolios: allMdx(
          filter: { fields: { contentType: { eq: "portfolios" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `,
  )

  const navItems = pipe(
    filter(({ frontmatter }) => frontmatter.navigation !== null),
    filter(({ frontmatter }) => !!frontmatter.title),
    filter(({ frontmatter }) => frontmatter.title !== 'Catalysing'),
    sortBy(path(['frontmatter', 'navigation', 'order'])),
    map(({ fields, frontmatter }) => {
      const { slug } = fields
      const { title, navigation } = frontmatter
      const pageCategories = pathOr(
        { edges: [] },
        [title.toLowerCase()],
        categories,
      )

      return {
        label: title,
        thumbnailSrc: navigation.thumbnailSrc,
        excerpt: navigation.excerpt,
        subItems: [
          {
            label: 'Overview',
            url: slug,
          },
          ...flattenCategories(pageCategories).map(cat => ({
            label: cat.title,
            url: cat.slug,
          })),
        ],
      }
    }),
  )(pages)

  return (
    <MainNav
      items={navItems.map(item => {
        if (item.label === 'Events') {
          return {
            ...item,
            subItems: [
              {
                label: 'Upcoming Engagement Opportunities',
                url: '/convening',
              },
              {
                label: 'Past events',
                url: '/convening/past-events',
              },
            ],
          }
        }

        // remove opportunities, added under About Us
        // if (item.label === 'Catalysing') {
        //   return {
        //     ...item,
        //     subItems: item.subItems.filter(
        //       subItem => subItem.label !== 'Opportunities',
        //     ),
        //   }
        // }

        if (item.label === 'About Us') {
          return {
            ...item,
            subItems: [
              ...item.subItems,
              {
                label: 'Our Team',
                url: '/about-us/team/',
              },
              {
                label: 'Our Scholars',
                url: '/about-us/scholars/',
              },
              {
                label: 'Opportunities',
                url: '/catalysing/category/opportunities/',
              },
              {
                label: 'Our Resources',
                url: '/about-us/our-resources/',
              },
              {
                label: 'Our Partners',
                url: '/about-us/our-partners/',
              },
              {
                label: 'Press Office',
                url: '/about-us/press-office/',
              },
              {
                label: 'Contact Us',
                url: '/about-us/contact/',
              },
            ],
          }
        }

        return item
      })}
    />
  )
}

export default Navbar
