import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './PullQuote.module.css'

const PullQuote = ({
  className = '',
  align = 'none',
  kind = 'base',
  body = '',
  caption = '',
}) => {
  const pullQuoteClassNames = classNames([styles.pullQuote, className], {
    [styles.isReverse]: kind === 'reverse',
    [styles.isBreaker]: kind === 'breaker',
    [styles.alignLeft]: align === 'left',
    [styles.alignRight]: align === 'right',
  })

  return (
    <blockquote className={pullQuoteClassNames}>
      <p>{body}</p>
      <cite>{caption}</cite>
    </blockquote>
  )
}

PullQuote.propTypes = {
  className: PropTypes.string,
  align: PropTypes.oneOf(['none', 'left', 'right']),
  kind: PropTypes.oneOf(['base', 'reverse', 'breaker']),
  body: PropTypes.string,
  caption: PropTypes.string,
}

export default PullQuote
