import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Accordion as AccessibleAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import Typography from '../Typography/Typography'
import './Accordion.module.css'

const Accordion = ({ className, items }) => {
  const accordionClassNames = classNames(['accordion', className])

  return (
    <AccessibleAccordion className={accordionClassNames}>
      {items.map((item, i) => {
        const panelClasses = classNames(['accordion__panel', 'grid'], {
          'grid-cols-2 gap-4': item.columns === 2,
          'grid-cols-3 gap-4': item.columns === 3,
        })
        return (
          <AccordionItem key={i}>
            <AccordionItemHeading>
              <AccordionItemButton>
                {item.iconUrl && <img src={item.iconUrl} />}
                <Typography tag="span" type="accordionHeader">
                  {item.title}
                </Typography>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={panelClasses}>
              {item.content}
            </AccordionItemPanel>
          </AccordionItem>
        )
      })}
    </AccessibleAccordion>
  )
}

Accordion.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      columns: PropTypes.oneOf([1, 2, 3]),
      title: PropTypes.string.isRequired,
      iconUrl: PropTypes.string,
      content: PropTypes.node,
    }),
  ).isRequired,
}

export default Accordion
