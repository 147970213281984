import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import BreadcrumbItem from './BreadcrumbItem/BreadcrumbItem'
import styles from './Breadcrumbs.module.css'

const Breadcrumbs = ({ className = '', children }) => {
  const classes = classNames([styles.breadcrumbs, className])

  return (
    <nav className={classes}>
      <ul>{children}</ul>
    </nav>
  )
}

// const childrenComponentPropTypes = PropTypes.oneOfType(
//   PropTypes.instanceOf(BreadcrumbItem),
// )

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  // children: PropTypes.oneOfType(
  //   PropTypes.arrayOf(childrenComponentPropTypes),
  //   childrenComponentPropTypes,
  // ).isRequired,
}

Breadcrumbs.Item = BreadcrumbItem

export default Breadcrumbs
