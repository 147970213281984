import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import Typography from '../Typography/Typography'
// import Img from '../../gatsbyWrappers/Img'
import styles from './IonoPodcast.module.css'

const IonoPodcast = ({ className, url }) => {
  const podcastClassNames = classNames([styles.podcast, className])

  const config = {
    playlist: 'none',
    description: 0,
    accent: '8DC63F',
    border: 'ffffff',
  }
  const configString = Object.keys(config)
    .map(key => `${key}=${config[key]}`)
    .join('&')

  return (
    <div className={podcastClassNames}>
      <iframe
        src={url + `?${configString}`}
        width="100%"
        height="130"
        frameBorder="0"
      />
    </div>
  )
}

IonoPodcast.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
}

export default IonoPodcast
