import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '../Typography/Typography'
import styles from './StatsPin.module.css'

const StatsPin = ({ className = '', color, pinHeader, pinBody }) => {
  const statsPinClassNames = classNames([styles.statsPin, className], {})

  return (
    <div className={`${color} ${statsPinClassNames}`}>
      <Typography type="aggregatorPinHead" className="text-white mt-2">
        {pinHeader}
      </Typography>
      <Typography
        className="text-white mt-1 text-center tracking-widest uppercase mr-3 ml-3"
        tag="p"
      >
        {pinBody}
      </Typography>
    </div>
  )
}

StatsPin.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  pinHeader: PropTypes.string,
  pinBody: PropTypes.string,
}

export default StatsPin
