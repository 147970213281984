import React from 'react'
import PropTypes from 'prop-types'
import styles from './MdxWrapper.module.css'

const MdxWrapper = ({ children }) => {
  return <div className={styles.root}>{children}</div>
}

MdxWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default MdxWrapper
