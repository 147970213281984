import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs, TransitionLink } from '../..'

const TemplateBreadCrumbs = ({ breadcrumbs, className }) => (
  <Breadcrumbs className={className}>
    {breadcrumbs.map((b, i) => (
      <Breadcrumbs.Item key={i}>
        {b.url ? (
          <TransitionLink to={b.url}>{b.label}</TransitionLink>
        ) : (
          <span>{b.label}</span>
        )}
      </Breadcrumbs.Item>
    ))}
  </Breadcrumbs>
)

export const breadcrumbsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
)

TemplateBreadCrumbs.propTypes = {
  className: PropTypes.string,
  breadcrumbs: breadcrumbsPropTypes,
}

export default TemplateBreadCrumbs
