import { graphql, useStaticQuery } from 'gatsby'

const useNavbarPages = () => {
  const data = useStaticQuery(
    graphql`
      query USE_PAGES_QUERY {
        allMdx(
          filter: {
            fields: { contentType: { eq: "pages" } }
            frontmatter: { navigation: { thumbnailSrc: { width: { gt: 0 } } } }
          }
        ) {
          nodes {
            id
            frontmatter {
              title
              navigation {
                excerpt
                order
                thumbnailSrc {
                  childCloudinaryMediaImage {
                    fixed(width: 265) {
                      base64
                      height
                      src
                      srcSet
                      width
                      aspectRatio
                    }
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    `,
  )
  return data.allMdx.nodes
  // return flattenNodes(data).filter(page => page !== null)
}

export default useNavbarPages
