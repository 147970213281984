import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './ThumbnailItem.module.css'

const ThumbnailItem = ({
  className = '',
  children,
  imageUrl = '',
  imageAlt = '',
}) => {
  const thumbnailItemClassNames = classNames([styles.item, className])

  return (
    <article className={thumbnailItemClassNames}>
      <section>{children}</section>
      {imageUrl && <img src={imageUrl} alt={imageAlt} />}
    </article>
  )
}

ThumbnailItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default ThumbnailItem
