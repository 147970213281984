import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Divider.module.css'

const Divider = ({ className = '', thickness = 1 }) => {
  const dividerClassNames = classNames([styles.divider, className], {
    [styles.thickness4]: thickness === 4,
  })

  return <hr className={dividerClassNames} />
}

Divider.propTypes = {
  className: PropTypes.string,
  thickness: PropTypes.oneOf([1, 4]),
}

export default Divider
